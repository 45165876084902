import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';
import { useExperiments } from '@wix/yoshi-flow-editor';
import { OOI_EXPERIMENTS } from '../../../../experiments';
import { ArrowDownIcon } from '../icons/arrow-down-icon';
import styles from './change-category-trigger.scss';

const ChangeCategoryTrigger: React.FC<PropsWithChildren<{ title: string }>> = ({
  children,
  title,
}) => {
  const { experiments } = useExperiments();
  const isMobileHeaderFixEnabled = experiments.enabled(
    OOI_EXPERIMENTS.FIX_MOBILE_HEADER_STYLING,
  );

  const className = classNames(
    styles.container,
    {
      'blog-card-background-color': !isMobileHeaderFixEnabled,
      'blog-text-color': !isMobileHeaderFixEnabled,
    },
    'categories-button',
    styles.boxShadow,
  );

  const iconClassNames = isMobileHeaderFixEnabled
    ? classNames(styles.icon, 'blog-navigation-container-fill')
    : classNames('blog-icon-fill', styles.icon);

  return (
    <div className={className}>
      <div className={styles.wrapper}>
        <span data-hook="category-title" className={styles.title}>
          {title}
        </span>
        <ArrowDownIcon className={iconClassNames} />
        {children}
      </div>
    </div>
  );
};

export default ChangeCategoryTrigger;
