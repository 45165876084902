import React from 'react';
import classNames from 'classnames';
import { useExperiments } from '@wix/yoshi-flow-editor';
import { OOI_EXPERIMENTS } from '../../../../experiments';
import useBlogMenuSettings from '../../hooks/use-blog-menu-settings';
import useFontClassName from '../../hooks/use-font-class-name';
import HeaderButtons from '../header-buttons';
import HeaderTitle from '../header-title';
import styles from './header.scss';

type Props = {
  left?: React.ReactNode;
  right?: React.ReactNode;
  menu?: React.ReactNode;
  hideNavBar?: boolean;
};

export const Header: React.FC<Props> = ({
  left = (
    <div className={styles.left}>
      <span className={styles.title}>
        <HeaderTitle />
      </span>
    </div>
  ),
  right = <HeaderButtons />,
  menu,
  hideNavBar,
}) => {
  const { showBlogMenu, showCategoryLabels } = useBlogMenuSettings();
  const { contentFontClassName } = useFontClassName();
  const { experiments } = useExperiments();

  const isMobileHeaderFixEnabled = experiments.enabled(
    OOI_EXPERIMENTS.FIX_MOBILE_HEADER_STYLING,
  );
  const showMenu = menu && showBlogMenu && showCategoryLabels;

  const containerClassName = classNames(
    isMobileHeaderFixEnabled
      ? `${[
          styles.headerContainer,
        ]} blog-navigation-container-font blog-navigation-container-color`
      : `${[styles.container]} blog-button-primary-text-color ${[
          contentFontClassName,
        ]}`,
  );

  const wrapperClassName = classNames(
    styles.header,
    isMobileHeaderFixEnabled
      ? 'blog-header-background-color'
      : 'blog-button-background-color',
  );

  return (
    <div className={containerClassName}>
      {!hideNavBar && showBlogMenu && (
        <div className={wrapperClassName}>
          {left}
          {right}
        </div>
      )}
      {showMenu && <div className="blog-header-background-color">{menu}</div>}
    </div>
  );
};

export default Header;
