import React from 'react';
import classNames from 'classnames';
import {
  useExperiments,
  useTranslation,
  type TFunction,
} from '@wix/yoshi-flow-editor';
import { getTPASettignsLiveSiteEditingDeprecated } from '@wix/communities-blog-client-common';
import { OOI_EXPERIMENTS } from '../../../../experiments';
import { importPrivateProfileActions } from '../../containers/private-profile-actions';
import ProtectedButton from '../../containers/protected-button';
import useAuth from '../../hooks/use-auth';
import useBlogMenuSettings from '../../hooks/use-blog-menu-settings';
import DropdownButton from '../dropdown-button';
import HeaderAvatarImage from '../header-avatar-image';
import NotificationsLink from '../link/notifications-link';
import NotificationButton from '../notification-button';
import { useSelector } from '../runtime-context';
import SearchButton from '../search-button';
import styles from './header-buttons.scss';

export const HeaderButtons: React.FC = () => {
  const { showLoginButton, showSearch } = useBlogMenuSettings();
  const { currentUser, isAuthenticated } = useAuth();
  const { t } = useTranslation();
  const isLiveSiteEditingDeprecated = useSelector(
    getTPASettignsLiveSiteEditingDeprecated,
  );
  const { experiments } = useExperiments();
  const isMobileHeaderFixEnabled = experiments.enabled(
    OOI_EXPERIMENTS.FIX_MOBILE_HEADER_STYLING,
  );

  return (
    <div className={classNames(styles.container, 'header-buttons')}>
      {showSearch ? renderSearchButton() : null}
      {showLoginButton
        ? renderNotificationButton(isAuthenticated, isMobileHeaderFixEnabled)
        : null}
      {showLoginButton && !isLiveSiteEditingDeprecated
        ? renderAvatar(isAuthenticated, currentUser, t)
        : null}
    </div>
  );
};

function renderSearchButton() {
  return (
    <div className={styles.searchButton}>
      <SearchButton />
    </div>
  );
}

function renderNotificationButton(
  isAuthenticated: boolean,
  isMobileHeaderFixEnabled: boolean,
) {
  const iconClassName = isMobileHeaderFixEnabled
    ? 'blog-navigation-container-fill'
    : 'blog-button-primary-icon-color';

  if (isAuthenticated) {
    return (
      <div className={styles.notificationButtonSpacing}>
        <NotificationButton
          // @ts-expect-error
          component={NotificationsLink}
          iconClassName={iconClassName}
          className="blog-icon-secondary-background-color"
        />
      </div>
    );
  }
}

function renderAvatar(
  isAuthenticated: boolean,
  currentUser: any,
  t: TFunction,
) {
  if (isAuthenticated) {
    return (
      <DropdownButton
        actionsContainerClassName={styles.profileActions}
        position={DropdownButton.POSITION_RIGHT}
        icon={<HeaderAvatarImage user={currentUser} />}
      >
        {async () => {
          const PrivateProfileActions = await importPrivateProfileActions();
          return <PrivateProfileActions user={currentUser} />;
        }}
      </DropdownButton>
    );
  }

  const signUpClassName = classNames(
    styles.signUp,
    'blog-button-primary-text-color',
    'header-buttons__sign-up',
  );

  return (
    <ProtectedButton tagName="span" className={signUpClassName}>
      {t('header-buttons.sign-up')}
    </ProtectedButton>
  );
}

export default HeaderButtons;
