/**
 * OOI-specific experiments should be listed here.
 */
export const OOI_EXPERIMENTS = {
  /**
   * https://bo.wix.com/petri/experiments/3206705
   */
  PREVIEW_NAVIGATION_TO_POST_PAGE: 'specs.wixBlog.PreviewNavigationToPost',
  /**
   * https://bo.wix.com/petri/experiments/3221548
   */
  FIX_MOBILE_HEADER_STYLING: 'specs.wixBlog.mobileHeaderStyling',
  /**
   * https://bo.wix.com/guineapig/experiments/3220067
   */
  USE_SETTINGS_V2: 'specs.wixBlog.UseSettingsV2OOI',
};
